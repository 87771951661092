import { Appointment, Provider, TenantUser, User } from '../../../types/models';
import { AppDispatch } from '../../store';
import { Auth } from 'aws-amplify';
import { Routes } from '../../../api/Routes';
import { login,
    logout,
    setUser,
    setTenantUser,
    setOnboardingComplete } from './index';
import { setAppointments } from '../coaching';
import { formatDateForSafari } from '../coaching/utils';
import PostEvents from '../../../services/PostEvents';
import { TenantApi } from '../../../api/UsersApi';

export const setCurrentTenantUser = (data: any) => async (dispatch: AppDispatch) => {
    dispatch(setTenantUser({ user: data as TenantUser }));
};

export const loginUser = (data: { user: User }) => async (dispatch: AppDispatch) => {
    const authUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
    let userId = authUser.getUsername();
    if (!userId && data.user?.id) {
        userId = data.user.id;
    }
    // Try to fetch user from core db, if the user doesn't exist create one
    const user = await TenantApi.get(`${Routes.tenant.user}/${userId}`);
    if (user !== undefined) {
        dispatch(login({ user: { ...user, onboardingComplete: user.onboardingComplete, email: authUser.attributes.email } }));
    }
};

export const logoutUser = () => async (dispatch: AppDispatch) => {
    Auth.signOut();
    dispatch(logout());
};

export const updateUser = (user: User) => async (dispatch: AppDispatch) => {
    dispatch(setUser({ ...user }));
};

export const fetchAppointments = ({ userId }: {userId: string}) => async (dispatch: AppDispatch) => {
    await TenantApi.get(`${Routes.healthie.users}/${userId}/appointments`)
        .then((res: Appointment[]) => {
            res.forEach(v => v.date = formatDateForSafari(v.date))
            dispatch(setAppointments({ appointments: res }));
        }).catch(() => {
            dispatch(setAppointments({ appointments: [] }));
        });
};

export const fetchUser = ({ userId }: {userId: string}) => async (dispatch: AppDispatch) => {
    TenantApi.get(`/users/${userId}`).then((res: TenantUser) => {
        dispatch(setCurrentTenantUser(res));
    });
};

export const markOnboardingComplete = (userId: string) => async (dispatch: AppDispatch) => {
    const emptyBody = {};
    await TenantApi.post(`/users/mark-onboarding-complete/${userId}`, emptyBody);
    await PostEvents.postOnboardingEvent('Onboarding Completed');
    dispatch(setOnboardingComplete(true));
};

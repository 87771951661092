import { Buttons, Spinner, VideoEmbed } from '@apps/common-ui';
import React, { useEffect, useState } from 'react';
import Vimeo from '@vimeo/player';
import * as S from './index.styles';
import ConfigurationService from '../../../services/ConfigurationService';
import { types } from '@apps/common-utilities';
import PostEvents from '../../../services/PostEvents';

type Props = {
  setShowWelcomeVideo: (show: boolean) => void;
  setVideoWatched: () => void;
  videoWatched: boolean;
};

const OnboardingVideo = ({ setShowWelcomeVideo, setVideoWatched, videoWatched }: Props) => {
    const [videoUrl, setVideoUrl] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const iframe = document.querySelector('iframe');
        if (iframe && videoUrl) {
            const player = new Vimeo(iframe || '');
            // allow button to be clicked when there's 10 or less seconds remaining to the video
            player.on('timeupdate', (data) => {
                if (data.duration - data.seconds <= 10) {
                    setVideoWatched();
                }
            });
        }
        // remove event listener on cleanup
        return () => {
            if (!iframe) return;
            const player = new Vimeo(iframe || '');
            player.off('timeupdate');
        };
    }, [loading, videoUrl]);

    const fetchVideo = () => {
        ConfigurationService.getConfiguration('ONBOARDING_VIDEO_URL').then((response: types.Configuration) => {
            setVideoUrl(response.configurationValue);
        }).catch().finally(() => {
            setLoading(false);
        });
    };

    useEffect(() => {
        if (videoWatched) {
            PostEvents.postOnboardingEvent('Welcome Video Watched');
        }
    }, [videoWatched]);

    if (!videoUrl || loading) {
        fetchVideo();
        return <Spinner />;
    }

    return (
        <S.Container>
            {videoUrl && !loading && (
                <VideoEmbed
                  title="Welcome to Breathesuite"
                  src={videoUrl}
                />
            )}
            <S.ButtonContainer>
                {videoWatched
          ? (
              <Buttons.Button onClick={() => setShowWelcomeVideo(false)}>
                  Continue
              </Buttons.Button>
          )
          : (
              <Buttons.Button buttonType="secondary" onClick={() => setShowWelcomeVideo(false)}>
                  Watch Later
              </Buttons.Button>
          )}
            </S.ButtonContainer>
        </S.Container>
    );
};

export default OnboardingVideo;

import React from 'react';
import * as S from './index.styles';
import { Buttons, Modal } from '@apps/common-ui';
import PostEvents from '../../services/PostEvents';
import OnboardingService from '../../services/OnboardingService';

interface Props {
    isOpen: boolean;
    closeModal: () => void;
    onTestCompleted: () => void
}

const TestZoomModal = ({ isOpen, closeModal, onTestCompleted }:Props) => {
    const handleZoomLink = (url: string) => {
        PostEvents.postOnboardingEvent('Zoom Tested');
        window.open(url, '_blank');
        OnboardingService.setTestZoomCompleted();
        onTestCompleted();
    };

    return (
        <Modal
          showModal={isOpen}
          dismissModal={closeModal}
          minWidth="25%"
          maxWidth="500px"
          minHeight="20%"
          maxHeight="100vh"
          showTopBar={false}
          closeOnOutsideClick
        >
            <S.ModalCloseButton
              size="medium"
              buttonType="tertiary"
              onClick={closeModal}
            >X Close
            </S.ModalCloseButton>
            <S.ModalContent>
                <S.ZoomTestIcon src="/ZoomIcon.svg" />
                <S.ModalText isBold>Test Zoom before your appointment</S.ModalText>
                <S.ModalText>Zoom will help you set up your<br />camera, microphone and speakers.</S.ModalText>
                <S.ModalText>Upon clicking start test:</S.ModalText>
                <S.ModalText>
                    1. Zoom will open
                    <br />2. Follow the steps provided by Zoom.
                    <br />3. Zoom will end the test
                    <br />4. Make sure to come back to <a href="http://my.breathesuite.com" target="_blank" rel="noreferrer">my.breathesuite.com</a> before your appointment!
                </S.ModalText>
                <S.ModalButton onClick={() => handleZoomLink('https://zoom.us/test')}>Start Test</S.ModalButton>
            </S.ModalContent>
        </Modal>
    );
};

export default TestZoomModal;
